import React from "react";
import { Controller, useForm } from "react-hook-form";
import InputField from "../componant/InputField";
export default function AddUser() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();

  const Submit = (data) => {
    console.log("data", data);
  };
  return (
    <>
      <section>
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-2 mt-3 ps-5 addUser_backArrow_container">
              <img
                className="me-1 addUser_backArrow"
                src="/asset/backArrow.png"
                alt="Back arrow"
              />{" "}
              Profile
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit(Submit)}>
              <div className="px-5 pt-3">
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Name</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="name"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Contact No</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="contactNo"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Email</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="email"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="email"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Date of Birth</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="dob"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Company name</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="company"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Locality</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="locality"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">City</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="city"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">pinCode</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="pinCode"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="text"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    <label className="addUser_label">Password</label>
                  </div>
                  <div className="col-4">
                    <Controller
                      control={control}
                      name="password"
                      render={(field) => (
                        <InputField
                          addUser_inputFieldCss="ps-2 addUser_inputField"
                          {...field}
                          type="password"
                        />
                      )}
                    />
                  </div>
                  <div />
                </div>
                <div className="row mt-3 ">
                  <div className="col-2">
                    {/* <label className="addUser_label">Password</label> */}
                  </div>
                  <div className="col-4">
                    <button className="addUser_submitBtn" type="submit">
                      SUBMIT
                    </button>
                  </div>
                  <div />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
