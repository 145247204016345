import React from "react";

export default function InputField(props) {
  return (
    <>
      <input
        className={`${props?.addUser_inputFieldCss}`}
        type={props.type}
        {...props.field}
      />
    </>
  );
}
