import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
const Pdf = () => {
  const componentRef = useRef([]);
  const data = [
    {
      id: 1,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 450,
      bookType: "PaperBack",
      code: "KEX15950",
      box: "EX 671",
      name: "ansu",
      weight: 650,
    },
    {
      id: 2,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 85,
      bookType: "HARDCOVER",
      code: "KEX16009",
      box: "EX 673",
      name: "ansu",
      weight: 175,
    },
    {
      id: 3,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 245,
      bookType: "PaperBack",
      code: "KEX16023",
      box: "EX 673",
      name: "ansu",
      weight: 325,
    },
    {
      id: 4,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 220,
      bookType: "PaperBack",
      code: "KEX16028",
      box: "EX 673",
      name: "ansu",
      weight: 345,
    },
    {
      id: 5,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 225,
      bookType: "HARDCOVER",
      code: "KEX16036",
      box: "EX 673",
      name: "ansu",
      weight: 560,
    },
    {
      id: 6,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 450,
      bookType: "PaperBack",
      code: "KEX15950",
      box: "EX 671",
      name: "ansu",
      weight: 650,
    },
    {
      id: 7,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 85,
      bookType: "HARDCOVER",
      code: "KEX16009",
      box: "EX 673",
      name: "ansu",
      weight: 175,
    },
    {
      id: 8,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 245,
      bookType: "PaperBack",
      code: "KEX16023",
      box: "EX 673",
      name: "ansu",
      weight: 325,
    },
    {
      id: 9,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 220,
      bookType: "PaperBack",
      code: "KEX16028",
      box: "EX 673",
      name: "ansu",
      weight: 345,
    },
    {
      id: 10,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 225,
      bookType: "HARDCOVER",
      code: "KEX16036",
      box: "EX 673",
      name: "ansu",
      weight: 560,
    },
    {
      id: 11,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 450,
      bookType: "PaperBack",
      code: "KEX15950",
      box: "EX 671",
      name: "ansu",
      weight: 650,
    },
    {
      id: 12,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 85,
      bookType: "HARDCOVER",
      code: "KEX16009",
      box: "EX 673",
      name: "ansu",
      weight: 175,
    },
    {
      id: 13,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 245,
      bookType: "PaperBack",
      code: "KEX16023",
      box: "EX 673",
      name: "ansu",
      weight: 325,
    },
    {
      id: 14,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 220,
      bookType: "PaperBack",
      code: "KEX16028",
      box: "EX 673",
      name: "ansu",
      weight: 345,
    },
    {
      id: 15,
      bookName: "Read It Yourself Set Of 6 Books",
      price: 225,
      bookType: "HARDCOVER",
      code: "KEX16036",
      box: "EX 673",
      name: "ansu",
      weight: 560,
    },
  ];
  const generatePDF = () => {
    const doc = new jsPDF();
    let pageNumber = 1;

    const addPageHeader = (doc, pageNumber) => {
        doc.setFontSize(16);
        doc.text(`Read It Yourself Set Of 6 Books (Page ${pageNumber})`, 20, 20);
        doc.setFontSize(12);
        doc.text("---------------------------------------------------------------------------------", 20, 30);
    };

    const headers = [["ID", "Book Name", "Price", "Book Type", "Code", "Box", "Name", "Weight"]];

    const rows = data.map(book => [
        book.id,
        book.bookName,
        book.price,
        book.bookType,
        book.code,
        book.box,
        book.name,
        book.weight
    ]);

    // Split the data into pages of 12 rows each
    let pageData = [];
    for (let i = 0; i < rows.length; i += 12) {
        pageData.push(rows.slice(i, i + 12));
    }

    pageData.forEach((page, index) => {
        if (index > 0) {
            doc.addPage();
            pageNumber++;
        }
        addPageHeader(doc, pageNumber);
        doc.autoTable({
            startY: 40,
            head: headers,
            body: page,
            styles: { fontSize: 10, cellPadding: 4 ,border:'1px solid #000'}, // Global styles for the table
            bodyStyles: { minCellHeight: 10 }, // Minimum cell height for rows
            headStyles: { fillColor: [22, 160, 133] }, // Header styles
            theme: 'striped' // Table theme
        });
    });

    doc.save("books.pdf");
  }
  // const generatePdf = async () => {
  //   const addPageHeader = (doc, pageNumber) => {
  //     return (
  //       <>
  //         {doc.text(
  //           <>
              // <div className="col-lg-12 ">
              //   <span className="table_address px-1">TO :</span>
              //   <span></span>
              // </div>
              // <div className="col-lg-12 ">
              //   <span className="table_address px-1">NAME : </span>
              //   <span className="user_data px-1"> ANSIYA </span>
              // </div>
              // <div className="col-lg-12  ">
              //   <span className="table_address px-1">ADDRESS :</span>
              //   <span className="user_data px-1">
              //     HOUSE NO:211A DR.SAKEER HUSSAIN NAGAR, PAZHAYATTINKUZHI,
              //     KOLLAM, KERALA, PINCODE:691020
              //   </span>
              // </div>
              // <div className="col-lg-6 ">
              //   <span className="table_address px-1">MOBILE :</span>
              //   <span className="user_data px-1"> 8281383223/9447048223 </span>
              // </div>
              // <div className="col-lg-6 ">
              //   <span className="table_address px-1">INS ID :</span>
              //   <span className="user_data px-1">ANSIYASHAROON</span>
              // </div>
  //           </>
  //         )}
  //       </>
  //     );
  //   };
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   let pageNumber = 1;
  //   const pageHeight = pdf.internal.pageSize.height;
  //   const pageWidth = pdf.internal.pageSize.width;
  //   console.log("componentRef", componentRef);
  //   // Looping Start
  //   data.forEach((book, index) => {
  //     if (index > 0 && index % 12 === 0) {
  //       pdf.addPage();
  //       pageNumber++;
  //       addPageHeader(pdf, pageNumber);
  //     }
  //     const y = 40 + (index % 12) * 30;
  //     pdf.setFontSize(12);
  //     pdf.text(`ID: ${book.id}`, 20, y);
  //     pdf.text(`Book Name: ${book.bookName}`, 40, y + 5);
  //     pdf.text(`Price: ${book.price}`, 40, y + 10);
  //     pdf.text(`Book Type: ${book.bookType}`, 40, y + 20);
  //     pdf.text(`Code: ${book.code}`, 40, y + 30);
  //     // pdf.text(`Box: ${book.box}`, 40, y + 40);
  //     // pdf.text(`Name: ${book.name}`, 40, y + 50);
  //     // pdf.text(`Weight: ${book.weight}`, 40, y + 60);
  //   });
  //   // Looping End
  //   // for (let i = 0; i < componentRef.current.length; i++) {
  //   //   const canvas = await html2canvas(componentRef.current[i], { scale: 2 });
  //   //   const imgData = canvas.toDataURL("image/png");
  //   //   const imgWidth = pageWidth;
  //   //   const imgHeight = (canvas.height * imgWidth) / canvas.width;

  //   //   if (i > 0) {
  //   //     pdf.addPage();
  //   //   }
  //   //   pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  //   // }

  //   // setIsLoading(false);
  //   pdf.save(` Pay Slip.pdf`);
  // };
  return (
    <>
      <section className="pdf_section">
        <button
          style={{
            width: "100px",
            height: "30px",
            borderRadius: "6px",
            margin: "10px",
            backgroundColor: "yellowgreen",
            color: "#000",
          }}
          // disabled={isLoading}
          type="submit"
          onClick={() => {
            generatePDF();
          }}
        >
          {"Download"}
        </button>
        <div className="container px-5">
          <div className="row px-5 py-1" ref={componentRef}>
            <span class="border-bottom"></span>
            <div className="col-lg-12 d-flex flex-wrap flex-column justify-content-end align-items-end">
              <span className="table_address">FROM : </span>
              <span className="table_address">KIDDIWINKS</span>
              <span className="table_address">NO:15,PERIYAR STREET,</span>
              <span className="table_address">BALAJI NAGAR,</span>
              <span className="table_address">CHENNAI-600070</span>
              <span className="table_address">9884878586</span>
            </div>
            <div className="table-responsive-sm">
              <table className="table  table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="table_data_head">S.NO</th>
                    <th className="table_data_head">BOOKNAMES</th>
                    <th className="table_data_head">PRICE</th>
                    <th className="table_data_head">BOOK TYPE</th>
                    <th className="table_data_head">CODE</th>
                    <th className="table_data_head">BOX</th>
                    <th className="table_data_head">NAME</th>
                    <th className="table_data_head">WEIGHT</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((v, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <th className="table_data">{i + 1}</th>
                          <td className="table_data">{v.bookName}</td>
                          <td className="table_data">{v.price}</td>
                          <td className="table_data">{v.bookType}</td>
                          <td className="table_data">{v.code}</td>
                          <td className="table_data">{v.box}</td>
                          <td className="table_data">{v.name}</td>
                          <td className="table_data">{v.weight}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pdf;
