// import logo from './logo.svg';
import '../FileUpload.css';
import FileUpload from '../components/FileUpload';
import Nav from '../components/Nav';
import GeneratePDF from '../components/NewFileIndex';
function File() {
  return (
<>
{/* <Nav/> */}
<section className='file_upload_header_section'>
<div className='container'>
<div className='row'>
<div className='col-lg-12 '>
  <FileUpload/>
  {/* <GeneratePDF/> */}
</div>
</div>
</div>
</section>
</>
  );
}

export default File;
