import logo from './logo.svg';
import './App.css';
import AddUser from './pages/AddUser';

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Pdf from "./components/Pdf";
import File from "./pages/File";
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
  const queryClient = new QueryClient()
  return (
    <>
      <BrowserRouter>
      <QueryClientProvider client={queryClient}>

        <Routes>
          <Route path="/" element={<File />} />
          <Route path="/PDF" element={<Pdf />} />
          <Route path="/addUser" element={<AddUser/>}/>
        </Routes>
      </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
