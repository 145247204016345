import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { useMutation } from "react-query";
import axios from "axios";
import AlretModel from "./AlretModel";
// import logoBase64 from '../../public/assets/image/logo.jpg'
export default function FileUpload() {
  const inputFile = useRef(null);
  let logoBase64 = process.env.PUBLIC_URL + "/assets/image/logo.jpg";
  const [isFile, setIsFile] = useState();
  const [isOpen, setIsOpen] = useState();
  const [isValue, setIsValue] = useState();
  const [isLoadings, setIsLoadings] = useState(false);

  const onClick = (e) => {
    inputFile.current.click();
  };

  console.log("inputFile", isLoadings);

  // File Download Start
  useEffect(() => {}, [isValue]);

  const groupBy = (array, key) => {
    return (
      array &&
      array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        return result;
      }, {})
    );
  };

  const groupedData = groupBy(isValue && isValue?.data, "insId");

  const addPageHeader = (
    doc,
    pageNumber,
    insId,
    name,
    toAddress,
    toPhoneNumber
  ) => {
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("TO :", 20, 15);

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`${name.toUpperCase()}`, 20, 20);
    doc.setFont("helvetica", "bold");

    // Set the address value and maximum width for each line
    const addressValue = `${toAddress}`;
    const maxWidth = 90; // Set the max width for text wrapping
    const addressLines = doc.splitTextToSize(addressValue.toUpperCase(), maxWidth);

    // Define initial positioning for address
    const addressStartX = 20;
    const addressStartY = 26;
    const addressLineHeight = 5; // Adjust line height if needed

    // Draw the address lines
    doc.setFont("helvetica", "normal");
    addressLines.forEach((line, index) => {
      doc.text(line, addressStartX, addressStartY + index * addressLineHeight);
    });

    // Calculate dynamic Y position for toPhoneNumber based on address lines
    const toPhoneNumberY = addressStartY + addressLines.length * addressLineHeight; 
    doc.setFont("helvetica", "normal");
    doc.text(`${toPhoneNumber}`, 20, toPhoneNumberY);

    doc.setFont("helvetica", "bold");
    doc.text(`ID :`, 133, toPhoneNumberY);
    doc.setFont("helvetica", "normal");
    doc.text(`${String(insId).toUpperCase()} `, 140, toPhoneNumberY);

    doc.line(10, toPhoneNumberY + 4, 190, toPhoneNumberY + 4); 

    // Logo placement
    const logoWidth = 40;
    const logoHeight = 16;
    doc.addImage(logoBase64, "PNG", 130, 10 ,logoWidth, logoHeight);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("FROM : ", 135, toPhoneNumberY + 10); 

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`${isValue?.data?.[0]?.fromName || ""}`, 135, toPhoneNumberY + 15);

    // Handle FROM address with line splitting
    const fromAddress = `${isValue?.data?.[0]?.fromAddress || ""}`;
    const midIndex = Math.floor(fromAddress.replace(/\s+/g, "").length / 2);
    const string1from = fromAddress.slice(0, midIndex);
    const string2from = fromAddress.slice(midIndex);

    const fromAddressStartX = 135;
    const fromAddressStartY = toPhoneNumberY + 20;
    doc.text(string1from, fromAddressStartX, fromAddressStartY);
    doc.text(string2from, fromAddressStartX, fromAddressStartY + addressLineHeight);

    doc.text(
      `${isValue?.data?.[0]?.fromPhoneNumber || ""}`,
      fromAddressStartX,
      fromAddressStartY + 10 // Adjust position to ensure no overlap
    );

    // Return the Y position for the table to be placed
    return fromAddressStartY + 10; 
  };

  const generatePDF = () => {
    // Start the loading state when the button is clicked
    

    if (isValue !== undefined) {
      const doc = new jsPDF();
      let pageNumber = 1;

      const headers = [
        [
          "ID",
          "Product Name",
          "Price",
          "Category",
          "Code",
          "Box",
          "Name",
          "Weight",
        ],
      ];

      Object.keys(groupedData).forEach((insId, groupIndex) => {
        console.log("groupedData", groupedData[insId][0].name);
        const rows = groupedData[insId].map((book, index) => [
          index + 1,
          book.bookNames,
          book.price,
          book.bookType,
          book.code,
          book.box,
          book.name,
          book.weight,
        ]);

        // Split the data into pages of 12 rows each
        let pageData = [];
        for (let i = 0; i < rows.length; i += 12) {
          pageData.push(rows.slice(i, i + 12));
        }

        pageData.forEach((page, index) => {
          if (groupIndex > 0 || index > 0) {
            doc.addPage();
            pageNumber++;
          }

          const toPhoneNumberY = addPageHeader(
            doc,
            pageNumber,
            insId,
            groupedData[insId][0].name,
            groupedData[insId][0].toAddress,
            groupedData[insId][0].toPhoneNumber
          );

          doc.autoTable({
            startY: toPhoneNumberY + 10, 
            head: headers,
            body: page,
            styles: {
              fontSize: 9,
              cellPadding: 4,
              lineColor: [173, 173, 173],
              lineWidth: 0.2,
            },
            bodyStyles: {
              minCellHeight: 8,
              lineColor: [173, 173, 173],
              lineWidth: 0.2,
            },
            headStyles: {
              rowHeight: 2,
              fillColor: [22, 160, 133],
              lineColor: [173, 173, 173],
              lineWidth: 0.2,
            },
            theme: "grid",
          });
        });
      });

      // Save the document and stop loading after it finishes
      doc.save("books.pdf");
      setIsLoadings(false);
    } else {
      // If no file is uploaded, show an alert and stop the loading state
      setIsOpen(true);
      setIsLoadings(false);
    }
  };

  // API Start
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) => axios.post(`${process.env.REACT_APP_HOST}`, postData),
    {
      onSuccess: (data) => {
        setIsOpen(true);
        setIsValue(data);
      },
      onError: (error) => {
        console.error("Error fetching data:", error);
      },
    }
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("FILEUPLOAD", file);
    const formData = new FormData();
    formData.append("file", file);
    console.log("FILEUPLOAD_formData", formData);
    mutate(formData);
  };


  return (
    <>
      <div className="fileupload_card">
        <p className="file_upload_label">File to PDF convert</p>

        <div
          style={{
            border: "2px dashed #003186",
            height: "80%",
            borderRadius: "5px",
            background: "#4068f38c",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
          onClick={onClick}
        >
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e)}
            accept=".xlsx"
          />
          <img src="/File.webp" style={{ width: "70px" }} />
          <p className="para_for_fileupload">
            Drag a file here, file size is less then 50MB
          </p>
          <p className="para_for_fileupload">or</p>
          <button className="file_upload_btn">Choose File</button>
        </div>
        <div className="--model-btn">
          <button className="file_upload_btn  " onClick={() => generatePDF()}>
          {isLoadings?"Loading...":" Convert to PDF"}
           
          </button>
        </div>
      </div>
      <AlretModel
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isText={
          isValue !== undefined
            ? "File Upload Successfully"
            : "Kindly Upload a File "
        }
      />
    </>
  );
}
