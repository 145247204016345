import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export default function AlretModel(props) {
    const handleClose = () => props?.setIsOpen(false);
    console.log("props&&props?.isText",props&&props?.isText);
  return (
    <Modal
    open={props?.isOpen}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div style={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  background: '#fff',
  p: 4,
  borderRadius:9
}}>

      <p style={{fontSize:'20px',fontWeight:600,    textAlign: 'center',
    margin: '0px',
    padding: '20px'}}>
    {
      props&&props?.isText
    }
      
      </p>
  
    </div>
  </Modal>
  )
}
